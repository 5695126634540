<template>
    <Navbar/>
      <section id="project2Site">
        <div class="project-content" style="margin-bottom:200px;">
          <br>
          <br>
          <br>
          <div style="height:20px;">
            <v-img @click="goBack" class="back-icon" src="../assets/back_icon.svg" alt="Back Icon"></v-img>
          </div>

          <div class="project-details" style="background-color:white">

            <p class="small-text">SCHOOL PROJECT</p>
            <p class="large-text">CMS - "Content Management System"</p>
            <hr style="border-color: #29425c; width: 100px; margin-right: auto; margin-left: auto; border-width: 3px; border-radius: 5px;margin-top:5px; margin-bottom:10px;">
            <p class="medium-text">This project involves a DAM (Digital Asset Management). 
                      The end product aims to enable users to manage media files.</p>
          </div>
          
          
          <div class="custom-section">
            <div class="custom-title" >
              <p>Overview</p>
              <hr style="border-color: black; width: 100px; border-width: 1px; border-radius: 5px; ">
            </div>
            <div class="custom-text">

              An online Digital Asset Management (DAM) system is to be implemented for SZ-YBBS.
               This system is designed to manage multimedia assets and provide them to defined 
               user groups across various channels. The UI/UX design is a critical component in the realization of this project.
                The user interface should be comprehensible and easily learnable for each user.
            </div>
          </div>

          <div class="custom-section2">
            <div class="custom-text2">

              In the implementation, VueJS was employed for the web interface. 
              A Headless CMS was chosen for the backend, where the frontend and backend are decoupled within the Headless CMS. 
              The interface of the Headless CMS operates through a REST API, facilitating access for websites and apps. 
              Both a web interface and a mobile application were developed.
            </div>
            <div class="custom-title2" >
              <p>Realisation</p>
              <hr style="border-color: black; width: 100px; border-width: 1px; border-radius: 5px; ">
            </div>
          </div>
          <br>
          <br>
          <div class="galleryUeberschrift">
              <span style="font-family:Montserrat-Bold">Project</span> <span style="font-family:Montserrat-Light;">Photos</span>
              <hr class="photoHr" style="border-color: #29425c; width: 180px;border-width: 3px; border-radius: 4px; margin-top:0px;margin-bottom:0px;">
          </div>

          <div class="grid-gallery" style="padding-bottom:1px;">

            <div class="grid-item">
              <a :href="require('../assets/img/project2/home_cms.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project2/home_cms.png')" />

              </a>
            </div>

            <div class="grid-item">
              <a :href="require('../assets/img/project2/dashboard_cms1.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project2/dashboard_cms1.png')" />

              </a>
            </div>

            <div class="grid-item">
              <a :href="require('../assets/img/project2/dashboard_cms2.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project2/dashboard_cms2.png')" />

              </a>
            </div>
            <div class="grid-item">
              <a :href="require('../assets/img/project2/dashboard_cms3.png')" data-lightbox="gridImage"> 
                <img :src="require('../assets/img/project2/dashboard_cms3.png')" />

              </a>
            </div>
            <div class="grid-item">
              <a :href="require('../assets/img/project2/dashboard_cms4.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project2/dashboard_cms4.png')" />

              </a>
            </div>
            
            
          </div>


        
             <!-- Zurück-Button -->
          <v-btn @click="goBack" class="back-button buttonVue" style="flex-basis: 100%;margin-top:30px;">
            <v-icon>mdi-chevron-left</v-icon>
              Back
          </v-btn>

          <br>
          <br>




        </div>


      </section>
    <Footer/>
</template>
  
  <script>
  import Navbar from '../components/NavbarComponent.vue'
  import Footer from '../components/FooterComponent.vue'
  import $ from 'jquery';
  import 'lightbox2/dist/css/lightbox.min.css';
  import lightbox from 'lightbox2/dist/js/lightbox-plus-jquery.min';

  export default {
      name: 'DetailsPageProject1',
  
      components: {
       Navbar,
       Footer,
      },
      
      data: () => ({
  
      }),

      mounted() {
      // Verwende jQuery für Lightbox
      lightbox.option({
        'resizeDuration': 200,
        'disableScrolling': true,
        'alwaysShowNavOnTouchDevices': true,
        'wrapAround': true,

        'jQuery': $,

      });
    },
      methods: {
        goBack() {
          // Zurück zur vorherigen Seite
          window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        }
      }
    }
  </script>

  <style>


  </style>
    