<template>
    <Navbar/>
      <section id="project4Site">
        <div class="project-content" style="margin-bottom:200px;">
          <br>
          <br>
          <br>
          <div style="height:20px;">
            <v-img @click="goBack" class="back-icon" src="../assets/back_icon.svg" alt="Back Icon"></v-img>
          </div>

          <div class="project-details" style="background-color:white">

            <p class="small-text">SCHOOL PROJECT</p>
            <p class="large-text">Web-Typography </p>
            <hr style="border-color: #29425c; width: 100px; margin-right: auto; margin-left: auto; border-width: 3px; border-radius: 5px;margin-top:5px; margin-bottom:10px;">
            <p class="medium-text">The project aims to create a CSS framework emphasizing typography,
                       built on the Skeleton framework and integrated into the HTML5 Boilerplate.</p>
          </div>
          
          
          <div class="custom-section">
            <div class="custom-title" >
              <p>Overview</p>
              <hr style="border-color: black; width: 100px; border-width: 1px; border-radius: 5px; ">
            </div>
            <div class="custom-text">
              The CSS framework to be developed is intended to serve as a foundational template for future projects, with a primary focus on typography.
              Typography structures information and plays a crucial role in the design of user interfaces, 
              influencing readability, aesthetics, and the overall user experience. 
            </div>
          </div>

          <div class="custom-section2">
            <div class="custom-text2">

              In the implementation, attention was given to the foundational principles of typography, including base font sizes, 
              and typographic composition techniques, both of which significantly contribute to UX (User Experience). 
              Typography and composition techniques are integral components of communication design and usability.
              Additionally, diverse approaches in the field of web typography were experimented with.
            </div>
            <div class="custom-title2" >
              <p>Realisation</p>
              <hr style="border-color: black; width: 100px; border-width: 1px; border-radius: 5px; ">
            </div>
          </div>
          <br>
          <br>
          <div class="galleryUeberschrift">
              <span style="font-family:Montserrat-Bold">Project</span> <span style="font-family:Montserrat-Light;">Photos</span>
              <hr class="photoHr" style="border-color: #29425c; width: 180px;border-width: 3px; border-radius: 4px; margin-top:0px;margin-bottom:0px;">
          </div>

          <div class="grid-gallery" style="padding-bottom:1px;">

            <div class="grid-item">
              <a :href="require('../assets/img/project4/home_typografie.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project4/home_typografie.png')" />

              </a>
            </div>

            <div class="grid-item">
              <a :href="require('../assets/img/project4/home_typografie1.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project4/home_typografie1.png')" />

              </a>
            </div>

            <div class="grid-item">
              <a :href="require('../assets/img/project4/home_typografie2.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project4/home_typografie2.png')" />

              </a>
            </div>
            <div class="grid-item">
              <a :href="require('../assets/img/project4/home_typografie3.png')" data-lightbox="gridImage"> 
                <img :src="require('../assets/img/project4/home_typografie3.png')" />

              </a>
            </div>


            
            
          </div>


        
             <!-- Zurück-Button -->
          <v-btn @click="goBack" class="back-button buttonVue" style="flex-basis: 100%;margin-top:30px;">
            <v-icon>mdi-chevron-left</v-icon>
              Back
          </v-btn>

          <br>
          <br>




        </div>


      </section>
    <Footer/>
</template>
  
  <script>
  import Navbar from '../components/NavbarComponent.vue'
  import Footer from '../components/FooterComponent.vue'
  import $ from 'jquery';
  import 'lightbox2/dist/css/lightbox.min.css';
  import lightbox from 'lightbox2/dist/js/lightbox-plus-jquery.min';

  export default {
      name: 'DetailsPageProject1',
  
      components: {
       Navbar,
       Footer,
      },
      
      data: () => ({
  
      }),

      mounted() {
      // Verwende jQuery für Lightbox
      lightbox.option({
        'resizeDuration': 200,
        'disableScrolling': true,
        'alwaysShowNavOnTouchDevices': true,
        'wrapAround': true,

        'jQuery': $,

      });
    },
      methods: {
        goBack() {
          // Zurück zur vorherigen Seite
          window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        }
      }
    }
  </script>

  <style>


  </style>
    