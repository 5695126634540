<template>
    <Navbar/>
    <div style="margin-bottom:200px;  padding: 70px 20px;">

      <div style="height:20px;">
            <v-img @click="goBack" class="back-icon" src="../assets/back_icon.svg" alt="Back Icon"></v-img>
      </div>
      <div class="impressumContainer" style="background-color:white">

        <h1 class="impressumHeadline">Impressum</h1>

        <p class="impressumText">Tobias Forsthofer</p>
        <p class="impressumText">3684 St. Oswald</p>
        <p class="impressumText">Austria</p>

        <br>
        <p><span class="impressumTitle">E-Mail: </span><span class="impressumText">tobias.forsthofer@icloud.com</span></p>
        <p><span class="impressumTitle">Tel.: </span><span class="impressumText">+(43) 677-614-745-30</span></p>

        <br>
        <br>
        <h2 class="impressumSubheadline">Urheberrechtshinweis</h2>
        <p class="impressumText">Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. 
          <br>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.
        </p>


        <br>
        <br>
        <h2 class="impressumSubheadline">Google Dienste</h2>
        <p class="impressumText"> Auf unserer Seite werden folgende Google Dienste verwendet: Schriften von Google Fonts: Google Fonts ist ein Dienst der Google Inc. 
          („Google“). Die Einbindung dieser Web Fonts erfolgt lokal.
        </p>


        <br>
        <br>
        <h2 class="impressumSubheadline">Icons</h2>
        <p class="impressumText">Icons erstellt von <a href="https://www.flaticon.com/de/autoren/freepik" title="Freepik">
           Freepik </a> von <a href="https://www.flaticon.com/de/" title="Flaticon">www.flaticon.com'</a>
        </p>
       

        <br>
        <br>
        <h2 class="impressumSubheadline">Image-Lightbox</h2>
        <p class="impressumText">Created by Lokesh Dhakar        
          <br>
          Homepage: <a href="https://lokeshdhakar.com/projects/lightbox2/#getting-started" title="Lightbox"> Lightbox</a> 
        </p>

        <br>
        <br>
        
      </div>

    </div>
    <Footer/>
</template>
  
  <script>
  import Navbar from '../components/NavbarComponent.vue'
  import Footer from '../components/FooterComponent.vue'
  
  export default {
      name: 'ImpressumPage',
  
      components: {
       Navbar,
       Footer,
      },
  
      data: () => ({
  
      }),
      methods: {
        goBack() {
          // Zurück zur vorherigen Seite
          window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        }
      },
    }
  </script>
    