<template>
  <Navbar/>
  <section class="home" >

    <div class="homepage">

      <div class="home-content" >
        
        <div>
          <!--<p style="font-size:1rem;padding-bottom:10px;">This website is a work in progress!</p>-->
          <br>
          <br>
          <h1 class="ueberschriftH1">Hi, I'm Tobias Forsthofer</h1>
          <h3 :style="`height: ${containerHeight}px`">
            <span class="typewriter">{{ animatedText }}</span>
          </h3>
          <br>
          <p class="justifyContent" style="font-family:Montserrat-Regular;" >
            I am an enthusiastic software developer. I have a passion for coding and creating innovative solutions.
             Whether it's building applications or solving complex problems, I find joy in the process of software development. 
          </p>
          <br>
          <div class="btn-box">
            <div href="#" class="scroll-button" data-target="aboutme"  @click="scroll('about')">About me</div>
            <div href="#" class="scroll-button" data-target="aboutme" @click="scroll('contact')">Let's Talk</div>
          </div>

          <div class="iconDisplayNone" style="padding-top:5%;">
            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-1 white--text no-box-shadow"
              icon
              @click="openLink(getIconLink(icon))"
            >
              <v-icon size="24px" class="white--text" >
                {{ icon }}
              </v-icon>
            </v-btn>

          </div>

        </div>

        <!--<div class="home-sci">
          <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-1 white--text no-box-shadow"
              icon
              @click="openLink(getIconLink(icon))"
            >
              <v-icon size="24px" class="white--text" >
                {{ icon }}
              </v-icon>
            </v-btn>
        </div>-->
      </div>


      <div class="content_portrait" >
        <v-img
          :src="require('../assets/img/forsthofer.png')"
          alt="Tobias Forsthofer"
          class="my-3"
          contain
        />

      </div>

      <!--<div class="circle">
        <v-img
          :src="require('../assets/img/forsthofer2.png')"
          alt="Tobias Forsthofer"
          class="my-3"
          contain
        />

      </div>-->

    </div>
    <div class="iconhomediv" > <!--Div hat 5vh bekommen home 85 und Wave 10-->
        <div style="position:absolute; bottom:0; left:0; right:0;color:white;">
          <div class="scroll-down"  >
                  <div class="circle" id="scroll-down-circle" @click="scroll('about')"> <!-- About Scroll Button hinzugefügt-->
                      <div class="arrow"></div>
                  </div>
            </div>
        </div>

    </div>

    <div style="width:auto;height:auto; ">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g class="parallax">
            <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(93, 124, 156,0.7)" />
            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(93, 124, 156,0.5)" />
            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(93, 124, 156,0.3)" />
            <use xlink:href="#gentle-wave" x="48" y="7" fill="rgb(93, 124, 156)" />
          </g>
        </svg>
    </div>
 


  <section id="about">
    <div class="aboutme_content" style="color:black;">

      <div class="containerAboutMe" ref="aboutMeName">

   
          <h1 class="aboutme-title">ABOUT ME</h1>
          <h2  class="name" v-if="showNameBar">Tobias</h2>
       
          <v-img
            :src="require('../assets/img/collage_portfolio_rahmenKlein2.png')"
            alt="Tobias Forsthofer"
            class="my-3 imgAboutMe"
            contain
            style="filter: grayscale(15%);"
          /> <!-- ein hover effekt mit opacity immer heller bild schalten-->
     

      </div>


      <div class="containerAboutMe" style="background-color: white;">
          <h2 style="color:#29425c;font-style:italic;" class="aboutmeHeadline"> TOBIAS - 20 - SOFTWAREENGINEER</h2>
          <br>
          <p class="justifyContent aboutmeText">
            My name is Tobias Forsthofer, and I am 20 years old. I currently live in Lower Austria, and I am an enthusiastic software developer. 
            In elementary school, I was already passionate about mathematics, and the fascination with solving various problems 
            in software development has endured to this day. Collaborating with other developers and the opportunity 
            to be part of a creative team are aspects that continually captivate me in software development.
          </p>

          <br><!--<hr style="border-color: #29425c;width:30%;margin-right:auto;margin-left:auto;">-->

          <p class="justifyContent aboutmeText">
            My ambition is to continually expand my skills and knowledge in the field of software development.
            This website therefore serves as my personal portfolio and progress journal.
            I'd be delighted to connect with you or cooperate on projects together. 
            Let's connect, collaborate, and bring ideas to life together!
          </p>
          <br>
          <br class="ZeilensprungAusblenden">
          <!--Radial Bar-->
          <div class="radialdivabout" style="min-height:90px;">
            <div class="radial-bars" ref="radialBars">

              <div class="radial-bar" v-if="showRadialBar"> 
                <svg x="0px" y="0px" viewBox="0 0 200 200">
                  <circle class="progress-bar" cx="100" cy="100" r="80"> </circle>
                  <circle class="path path-1" cx="100" cy="100" r="80"> </circle>
                </svg>
                <div class="percentage">80%<br><span style="font-weight:normal;">Creative</span></div>
                <!--<div class="text">Creative</div>-->
              </div>

              <div class="radial-bar" v-if="showRadialBar"> 
                <svg x="0px" y="0px" viewBox="0 0 200 200">
                  <circle class="progress-bar" cx="100" cy="100" r="80"> </circle>
                  <circle class="path path-2" cx="100" cy="100" r="80"> </circle>
                </svg>
                <div class="percentage">90%<br><span style="font-weight:normal;">Comms</span></div>
                <!--<div class="text">Communication</div>-->
              </div>

              <div class="radial-bar" v-if="showRadialBar"> 
                <svg x="0px" y="0px" viewBox="0 0 200 200">
                  <circle class="progress-bar" cx="100" cy="100" r="80"> </circle>
                  <circle class="path path-3" cx="100" cy="100" r="80"> </circle>
                </svg>
                <div class="percentage">85%<br><span style="font-weight:normal;">Problem Solving</span></div>
                <!--<div class="text">Problem Solving</div>-->
              </div>

              <div class="radial-bar" v-if="showRadialBar"> 
                <svg x="0px" y="0px" viewBox="0 0 200 200">
                  <circle class="progress-bar" cx="100" cy="100" r="80"> </circle>
                  <circle class="path path-4" cx="100" cy="100" r="80"> </circle>
                </svg>
                <div class="percentage">95%<br><span style="font-weight:normal;">Teamwork</span></div>
                <!--<div class="text">Teamwork</div>-->
              </div>

            </div>
          </div>
          <br>
          <!--   -->
        
      </div>


    </div>
  </section>

  </section>


  <section id="project">

      
    <div class="projectPaddingBottom" style="background-color: rgb(249,249,249);position:relative;">

      <div class="custom-wave-container">
        <svg class="custom-wave" viewBox="0 0 1440 100" preserveAspectRatio="none">
          <path d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"></path>
        </svg>
      </div>

      <h1 class="sub-title-projects"> Latest <span> Projects</span></h1>

      <div class="container_latesprojects">
        
        <div class="subcontainer_latestprojects">
      <p>
        <span ref="yearsCount" class="count-up">{{ animatedCounts.yearsCount }}</span><span>+</span>
        <br>Years of Experience
      </p>
    </div>

    <div class="subcontainer_latestprojects">
      <p>
        <span ref="projectsCount" class="count-up">{{ animatedCounts.projectsCount }}</span><span>+</span>
        <br>Complete Projects
      </p>
    </div>

    <div class="subcontainer_latestprojects">
      <p>
        <span ref="coffeesCount" class="count-up">{{ animatedCounts.coffeesCount }}</span><span>+</span>
        <br>Drunk Coffees
      </p>
    </div>

      </div>

      <br>

      <div class="slider"  >

        <div class="slide-container swiper" >

            <div class="slide-content">

              <div class="card-wrapper swiper-wrapper">

                <!------------------------ Card Beginn --------------------------------->
                <div class="card swiper-slide">
                  <div class="image-content">
                    <span class="overlayt"></span>

                      <div class="card-image" >

                        <v-img
                          src="../assets/img/tnt_diplo.png"
                          alt="TNT - „The New Time“"
                          class="card-img"
                          cover
                        />


                      </div>

                  </div>

                  <div class="card-content">

                    <h2 class="nameslider">TNT - „The New Time“</h2>
                    <p class="description">Migration of time recording to a microfrontend architecture with open RESTFUL backend standard
                    </p>
                    
                    <!-- Verwende router-link, um zur detaillierten Ansicht zu navigieren -->
                    <router-link to="/detailsProject1">
                      <v-btn class="buttonVue">View More</v-btn>
                    </router-link>

                  </div>

                </div>    

                <!------------------------ Card Abstand --------------------------------->
                <div class="card swiper-slide">
                  <div class="image-content">
                    <span class="overlayt"></span>

                      <div class="card-image" >

                        <v-img
                          src="../assets/img/cms_home.png"
                          alt="CMS-Asset Management"
                          class="card-img"
                          cover
                        />

                      </div>

                  </div>

                  <div class="card-content">

                    <h2 class="nameslider">CMS-Asset Management</h2>
                    <p class="description">This project involves a DAM (Digital Asset Management). 
                      The end product aims to enable users to manage media files.
                    </p>
                    
                    <!-- Verwende router-link, um zur detaillierten Ansicht zu navigieren -->
                    <router-link to="/detailsProject2">
                      <v-btn class="buttonVue">View More</v-btn>
                    </router-link>

                  </div>

                </div>     
                
                <!------------------------ Card Abstand --------------------------------->
                <div class="card swiper-slide">
                  <div class="image-content">
                    <span class="overlayt"></span>

                      <div class="card-image" >

                        <v-img
                          src="../assets/img/delay_sports.png"
                          alt="CICD-Design Package"
                          class="card-img"
                          cover
                        />

                      </div>

                  </div>

                  <div class="card-content">

                    <h2 class="nameslider">CICD-Design Package</h2>
                    <p class="description"> 
                        As part of a competition, a CI/CD design package 
                        was developed for the <span style="font-style:italic;">Delay Sports</span> football club.
                    </p>
                    
                    <router-link to="/detailsProject3">
                      <v-btn class="buttonVue">View More</v-btn>
                    </router-link>

                  </div>

                </div>      

                <!------------------------ Card Abstand --------------------------------->
                <div class="card swiper-slide">
                  <div class="image-content">
                    <span class="overlayt"></span>

                      <div class="card-image">

                        <v-img
                          src="../assets/img/home_typo.png"
                          alt="Web-Typography"
                          class="card-img"
                          cover
                        />

                      </div>

                  </div>

                  <div class="card-content">

                    <h2 class="nameslider">Web-Typography</h2>
                    <p class="description">The project aims to create a CSS framework emphasizing typography,
                       built on the Skeleton framework and integrated into the HTML5 Boilerplate.
                    </p>
                    
                    <router-link to="/detailsProject4">
                      <v-btn class="buttonVue">View More</v-btn>
                    </router-link>

                  </div>

                </div>      
             
                <!-- Ende-->
                
              </div>

            
            </div>


            <div class="swiper-button-next swiper-navBtn"></div>
            <div class="swiper-button-prev swiper-navBtn"></div>
            <div class="swiper-pagination"></div>

 
        </div>
        
      </div>




      <div class="custom-wave-container" style="position: absolute; bottom: 0;width:100%;">
        <svg class="custom-wave" style="transform: scaleY(-1);" viewBox="0 0 1440 100" preserveAspectRatio="none">
          <path d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"></path>
        </svg>
      </div>



    </div> 

  </section>



  

  <section>
  <div style="padding-bottom:40px;">
    <br>
    <h1 class="sub-title title" style="margin-bottom:0px;"> 
        <span class="title-word title-word-1">This </span>
        <span class="title-word title-word-2">is </span>
        <span class="title-word title-word-3">My </span>
        <span class="title-word title-word-4">Experience</span>
    </h1>

    <p class="headlineExperience"> 

      "I am convinced that my passion for software development, UI/UX topics, 
      and my motivation to constantly evolve and
      embrace new challenges will make me a dedicated and enthusiastic team member."
    </p>

  

    <div class="skillswrapper" style="background-color: white">

      <div class="containerSkills1" >

        <div class="experience-section text-center" style="text-align:center;">
            <div class="containerExperience" style="display:inline-block;">
                <div class="job-list">
                  <div class="job-item first">
                      <p class="date">August 2023 - NOW</p>
                      <p class="position">Austrian Red Cross</p>
                      <p class="company">Civil Service</p>
                      <p></p>
                  </div>
                  <div class="job-item second">
                      <p class="date">September 2018 - June 2023</p>
                      <p class="position">IT-HTL Ybbs an der Donau</p>
                      <p class="company">Information Technology</p>
                      <p class="descriptionTimeline" style="font-style:italic;">IT professional expertise:</p>
                        <ul>
                          <li>IT basic education</li>
                          <li>Network technology and cybersecurity</li>
                          <li>Media technology/design</li>
                          <li>Web development</li>
                          <li>Software development</li>
                        </ul>
                        <br>
                  </div>
                  <div class="job-item third">
                      <p class="date"> July 2019,20,21</p>
                      <p class="position">Internship Stora Enso Ybbs</p>
                      <p class="company">IT department</p>
                      <p></p>
                  </div>
                  <div class="job-item fourth">
                      <p class="date">September 2014 - June 2018</p>
                      <p class="position">IT-NNÖMS Yspertal</p>
                      <!--<p class="company"></p>-->
                      <p></p>
                  </div>
                  <div class="job-item fifth">
                      <p class="date">September 2010 - June 2014</p>
                      <p class="position">Volksschule St. Oswald</p>
                      <!--<p class="company"></p>-->
                      <p></p>
                  </div>
                </div>
            </div>
        </div>

      <!--
        <div class="experience-section text-center"  >
            <div class="containerExperience">
              <div class="job-list">
                <div v-for="(job, index) in jobs" :key="index" class="job-item" :class="{ 'first': index === 0, 'second': index === 1, 'third': index === 2, 'fourth': index === 3 }">
                  <p class="date">{{ job.date }}</p>
                  <p class="position">{{ job.position }}</p>
                  <p class="company">{{ job.company }}</p>
                  <p>{{ job.description }}</p>
                </div>
              </div>
            </div>
        </div>
      -->
          
      </div>





      <div class="containerSkills2" style="padding-bottom:30px;" id="Skills" ref="technicalBars">

        <h2 class="headingBars headingBarsCodingSkills">Coding Skills</h2>
        <h2 class="subheadingBars" style="margin-bottom:10px;">DEVELOPING ON</h2>

        <div class="Technical-bars" > <!-- ref="technicalBars" da war es vorher !!!!!!!!!!-->

          <div class="bar" v-if="showTechnicalBars"> 
              <div class="info">
                <span>
                  <!--<box-icon color='#147bbc' style="background-color: yellow;" type='logo' name='html5'></box-icon> --> <!-- Derzeit nur Über Link in der INDEX.html SEITE !!!!!!!!!!!!!!!!!!!!!!!!-->
                  HTML, CSS, JS, Python, PHP
                </span>
              </div>
              <div class="progress-line web"> 
                <span></span>
              </div>
          </div>
 
          <div class="bar" v-if="showTechnicalBars"> 
              <div class="info">
                <span>
                  <!--<box-icon color='#b0bc1e' name='javascript' type='logo' ></box-icon>--> <!-- Derzeit nur Über Link in der INDEX.html SEITE !!!!!!!!!!!!!!!!!!!!!!!!-->
                  Vue.js, React, Dart
                </span>
              </div>
              <div class="progress-line jsframework"> 
                <span></span>
              </div>

          </div>
          <div class="bar" v-if="showTechnicalBars"> 
              <div class="info">
                <span>
                  <!--<box-icon color='#b0bc1e' name='javascript' type='logo' ></box-icon>--> <!-- Derzeit nur Über Link in der INDEX.html SEITE !!!!!!!!!!!!!!!!!!!!!!!!-->
                  C#, C++, .Net Core, Java
                </span>
              </div>
              <div class="progress-line backend"> 
                <span></span>
              </div>

          </div>

          <div class="bar" v-if="showTechnicalBars"> 
              <div class="info">
                <span>
                  <!--<box-icon color='#b0bc1e' name='javascript' type='logo' ></box-icon>--> <!-- Derzeit nur Über Link in der INDEX.html SEITE !!!!!!!!!!!!!!!!!!!!!!!!-->
                  DBS: MSSQL, MySQL, Oracle, OrientDB
                </span>
              </div>
              <div class="progress-line dbs"> 
                <span></span>
              </div>

          </div>

          <!--       Design Skills             -->
          <h2 class="headingBars headingBarsDesignSkills" v-if="showTechnicalBars">Design Skills</h2>
          <h2 class="subheadingBars" v-if="showTechnicalBars" style="margin-bottom:10px;">CREATIVE ABILITY</h2>

          <div class="bar" v-if="showTechnicalBars" style="margin-top:0px;"> 
              <div class="info">
                <span>
                  <!--<box-icon color='#c32ec9' name='python' type='logo' ></box-icon>--> <!-- Derzeit nur Über Link in der INDEX.html SEITE !!!!!!!!!!!!!!!!!!!!!!!!-->
                  UI/UX Design
                </span>
              </div>
              <div class="progress-line design"> 
                <span></span>
              </div>
          </div>

          <div class="bar" v-if="showTechnicalBars"> 
              <div class="info">
                <span>
                  <!--<box-icon color='#69bcbc' name='vuejs' type='logo' ></box-icon> --><!-- Derzeit nur Über Link in der INDEX.html SEITE !!!!!!!!!!!!!!!!!!!!!!!!-->
                  Adobe products (PS, AI, PR, LR)
                </span>
              </div>
              <div class="progress-line adobe"> 
                <span></span>
              </div>
          </div>

          
          <div class="bar" v-if="showTechnicalBars"> 
              <div class="info">
                <span>
                  <!--<box-icon color='#69bcbc' name='vuejs' type='logo' ></box-icon>--> <!-- Derzeit nur Über Link in der INDEX.html SEITE !!!!!!!!!!!!!!!!!!!!!!!!-->
                  Agile project management
                </span>
              </div>
              <div class="progress-line projectmanagement"> 
                <span></span>
              </div>
          </div>

        </div>


      </div>


      <!-- Radial Bars 
      <div class="container1" >

        <h1 class="heading1">Professional Skills</h1>

        <div class="radial-bars">

          <div class="radial-bar"> 
            <svg x="0px" y="0px" viewBox="0 0 200 200">
              <circle class="progress-bar" cx="100" cy="100" r="80"> </circle>
              <circle class="path path-1" cx="100" cy="100" r="80"> </circle>
            </svg>
            <div class="percentage">90%</div>
            <div class="text">Creative</div>
          </div>

          <div class="radial-bar"> 
            <svg x="0px" y="0px" viewBox="0 0 200 200">
              <circle class="progress-bar" cx="100" cy="100" r="80"> </circle>
              <circle class="path path-2" cx="100" cy="100" r="80"> </circle>
            </svg>
            <div class="percentage">60%<br>
              Communication
            </div>
            <div class="text">Communication</div>
          </div>

          <div class="radial-bar"> 
            <svg x="0px" y="0px" viewBox="0 0 200 200">
              <circle class="progress-bar" cx="100" cy="100" r="80"> </circle>
              <circle class="path path-3" cx="100" cy="100" r="80"> </circle>
            </svg>
            <div class="percentage">75%</div>
            <div class="text">Problem Solving</div>
          </div>

          <div class="radial-bar"> 
            <svg x="0px" y="0px" viewBox="0 0 200 200">
              <circle class="progress-bar" cx="100" cy="100" r="80"> </circle>
              <circle class="path path-4" cx="100" cy="100" r="80"> </circle>
            </svg>
            <div class="percentage">85%</div>
            <div class="text">Teamwork</div>
          </div>

        </div>

      </div>
      -->
    
    
    
    </div> 
  </div>
  </section>

  <section>

          
    <div style="padding-bottom:3%; background-color: rgb(249,249,249);position:relative;">

      <div class="custom-wave-container">
        <svg class="custom-wave" viewBox="0 0 1440 100" preserveAspectRatio="none">
          <path d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"></path>
        </svg>
      </div>

      <h1 class="sub-title-projects" style="padding-top:30px;"> My<span> Services</span></h1>

      <div class="services" style="padding-bottom:20px;">
          <div class="cardService">
                  <v-img
                    :src="require('../assets/icons/web-design.png')"
                    class="my-3"
                    alt="Web Design"
                    contain
                    height="50"
                  />
                  <div class="titleService">Web/Software Development</div>
                  <p>Designing state-of-the-art websites and applications to elevate your online presence.</p>
          </div>
          <div class="cardService">
                   <v-img
                    :src="require('../assets/icons/ux-design.png')"
                    class="my-3"
                    alt="UX Design"
                    contain
                    height="50"
                  />
                  <div class="titleService">UI/UX Design</div>
                  <p>Creation of engaging user interfaces and seamless user experiences for optimal design.</p>
          </div>
          <div class="cardService">
                  <v-img
                    :src="require('../assets/icons/kunden-service.png')"
                    class="my-3"
                    alt="Kunden Service"
                    contain
                    height="50"
                  />
                  <div class="titleService">IT-Service</div>
                  <p>When it comes to technical inquiries, I am at your disposal and ready to assist.</p>
          </div>
          <div class="cardService">
                  <v-img
                    :src="require('../assets/icons/graphy-design.png')"
                    class="my-3"
                    alt="Graphy Design"
                    contain
                    height="50"
                  />
                  <div class="titleService">Graphy Design</div>
                  <p>Whether it's creating logos, illustrations, or eye-catching posters – I turn your creative visions into impressive visual concepts.</p>
          </div>
          <div class="cardService">
                  <v-img
                    :src="require('../assets/icons/photography.png')"
                    class="my-3"
                    alt="Photography"
                    contain
                    height="50"
                  />
                  <div class="titleService">Photography</div>
                  <p>In photography, I am available for small photo projects as well as additional image editing.</p>
          </div>
          <div class="cardService">
                  <v-img
                    :src="require('../assets/icons/project-management.png')"
                    class="my-3"
                    alt="Project Management"
                    contain
                    height="50"
                  />
                  <div class="titleService">IT-Projects </div>
                  <p>I am open for any IT-projects and eager to collaborate with you to realize your ideas successfully.</p>
          </div>


        </div>

        
    <br> <!-- Zeilenumbrüche!!!!!!!!!! -->
    <br>

      <div class="custom-wave-container" style="position: absolute; bottom: 0;width:100%;">
        <svg class="custom-wave" style="transform: scaleY(-1);" viewBox="0 0 1440 100" preserveAspectRatio="none">
          <path d="M826.337463,25.5396311 C670.970254,58.655965 603.696181,68.7870267 447.802481,35.1443383 C293.342778,1.81111414 137.33377,1.81111414 0,1.81111414 L0,150 L1920,150 L1920,1.81111414 C1739.53523,-16.6853983 1679.86404,73.1607868 1389.7826,37.4859505 C1099.70117,1.81111414 981.704672,-7.57670281 826.337463,25.5396311 Z"></path>
        </svg>
      </div>

    </div> 
  </section>


  <section  id="contact" >

              
    <div style="padding-bottom:250px;" >

      <!--<h1 class="sub-title-projects" style="padding-top:30px;"> My<span> Contacts</span></h1>-->
        <br>

        <div class="headlineContact" ref="contactAnimation">
            <div v-if="showContactAnimation">
              <h1 class="titleContact" >
                <span class="zeileContact">Any <span style="color:#5d7c9c;">questions</span>?</span>
                <!--<span class="zeileContact"><span style="color:#5d7c9c;">Hi</span>, do you have <span style="color:#5d7c9c;">any</span> questions?</span>-->
              </h1>
              
              <h2 class="titleContact">
                <span class="zeileContact"><span style="color:#000;">Get in</span>  <span style="color:#5d7c9c;">touch
                               </span> <span style="color:#000;"> with </span><span style="color:#5d7c9c;"> me</span><span style="color:#000;">.</span></span> 
              </h2>

              <h3 class="titleContact">
                <span class="zeileContactFixed"><span style="color:#000;">Say </span><span style="color:#5d7c9c;">Hello</span><span style="color:#000;">.</span></span> 
              </h3>
          </div>
        </div>

        <div style="clear:both;">

        </div>
        <br>

        <div class="contactwrapper" >

          <div class="containerContact1" >

            <p class="contactTitle">Contact Me:</p>
            <p class="contactText">Feel free to reach out to me with any inquiries, questions, or collaboration opportunities. </p>

            <br>
            <br>
            <div class="ContainerDetailsAll">
                <div class="contactDetailsContainer">

                    <div class="contactDetailsImageContainer" >
                      <v-img
                        :src="require('../assets/icons/contact/phone.png')"
                        alt="Phone"
                        contain
                      />
                    </div>

                    <div class="contactDetailsTextContainer">
                      <p class="contactDetailsTitle">PHONE:</p>
                      <p class="contactDetailsText">+(43) 677-614-745-30</p>
                    </div>

                </div>

                <br>

                <div class="contactDetailsContainer">

                    <div class="contactDetailsImageContainer" >
                      <v-img
                        :src="require('../assets/icons/contact/email.png')"
                        alt="E-Mail"
                        contain
                      />
                    </div>

                    <div class="contactDetailsTextContainer">
                      <p class="contactDetailsTitle">E-Mail:</p>
                      <p class="contactDetailsText">tobias.forsthofer@icloud.com</p>
                    </div>

                </div>

                <br>

                <div class="contactDetailsContainer">

                    <div class="contactDetailsImageContainer" >
                      <v-img
                        :src="require('../assets/icons/contact/address.png')"
                        alt="Address"
                        contain
                      />
                    </div>

                    <div class="contactDetailsTextContainer">
                      <p class="contactDetailsTitle">ADDRESS:</p>
                      <p class="contactDetailsText">3684 St. Oswald, Austria</p>
                    </div>

                </div>
                
                <hr style="border-color: black; width: 100px; margin-right: auto; margin-left: auto; border-width: 1px; border-radius: 10px;margin-top:40px; margin-bottom:10px;">

                <div style="text-align:center;">

                  <v-icon
                    v-for="icon in icons"
                    :key="icon"
                    size="30px"
                    class="contactIcons mx-1 "
                    @click="openLink(getIconLink(icon))"
                  >
                    {{ icon }}
                  </v-icon>

                </div>
              </div>

          </div>



          <div class="containerContact2">

            <div class="contactQuestionContainer">

              <div class="contactQuestionImageContainer" >
                <v-img
                  :src="require('../assets/img/forsthofer2.png')"
                  alt="Tobias Forsthofer"
                  contain
                  class="borderImgContact"
                />
              </div>

              <div class="contactQuestionTextContainer">
                <p class="contactQuestionTitle"><img style="height:20px;padding-bottom:5px;padding-right:5px;" :src="require('../assets/img/circle.png')">Do you have a cool idea where I can contribute?</p>
                <img style="height:20px;" :src="require('../assets/img/arrow_down_blue.png')">
                <p class="contactQuestionTitle"><img style="height:20px;padding-bottom:5px;padding-right:5px;" :src="require('../assets/img/circle.png')">Do you need help with a project?</p>
                <img style="height:20px;" :src="require('../assets/img/arrow_down_blue.png')">
                <p class="contactQuestionTitle"><img style="height:20px;padding-bottom:5px;padding-right:5px;" :src="require('../assets/img/circle.png')">Interested in exchanging thoughts?</p>
                <img style="height:20px;" :src="require('../assets/img/arrow_down_blue.png')">
                <br>
                <p class="contactQuestionText"><img style="height:40px;padding-bottom:10px;" :src="require('../assets/img/arrow_right_blue.png')">Get in touch with me:</p>

                <a :href="'mailto:tobias.forsthofer@icloud.com'">
                  <v-btn class="buttonVueContact">
                    Write Me
                    <img style="height: 20px; padding-left: 5px;" :src="require('../assets/img/mail.png')">
                  </v-btn>
                </a>

              </div>

            </div>

          </div>

         
        </div>

        <!--<div style="text-align:center;width:50px;margin-left:auto;margin-right:auto;padding-top:100px;">
          <div class="circleUp" @click="scrollToTop">
            <div @click="scrollToTop" class="arrowUp"></div>
          </div>
        </div>
          -->

    </div> 
  

  </section>



  <!--
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/img/forsthofer2.png')"
          alt="Tobias Forsthofer"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 id="aboutme" class="display-2 font-weight-bold mb-3">
          Tobias Forsthofer - Coming Soon 
        </h1>

        <p class="subheading font-weight-regular">
          We are working on something awesome! Stay tuned for updates.
        </p>

        <div style="height:100vh;">
          ...

      </div>
      </v-col>

    </v-row>
  </v-container>
  -->



  <Footer />
</template>

<script>
import Navbar from '../components/NavbarComponent.vue'
import Footer from '../components/FooterComponent.vue'
/* Swiper Slider JS Einbindung */
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';


// -----------Count UP Projects!!!!!!!!---------
import { ObserveVisibility } from 'vue-observe-visibility'; 



//-------------------------- Arrow Smooth Scroll ----------------------------
document.addEventListener("DOMContentLoaded", function() {
    // Das Element auswählen
    const scrollDownCircle = document.getElementById("scroll-down-circle");

    // Event-Listener hinzufügen, um auf Klicks zu reagieren
    scrollDownCircle.addEventListener("click", function() {
        // Scrollen um 100vh (Viewport-Höhe) nach unten mit reibungslosem Effekt
        window.scrollTo({
            top: window.pageYOffset + window.innerHeight,
            behavior: "smooth"
        });
    });
});


//-------------------------- Buttons Homepage Scroll Smooth ----------------------------
document.addEventListener("DOMContentLoaded", function () {
        // Das Element auswählen
        const scrollDownCircle = document.getElementById("scroll-down-circle");

        // Event-Listener hinzufügen, um auf Klicks zu reagieren
        scrollDownCircle.addEventListener("click", function () {
            // Scrollen um 100vh (Viewport-Höhe) nach unten mit reibungslosem Effekt
            window.scrollTo({
                top: window.innerHeight,
                behavior: "smooth"
            });
        });
    });



export default {
  name: 'HomePage2',

  components: {
   Navbar,
   Footer,
  },

  directives: {
  'observe-visibility': ObserveVisibility,
},

  mounted() {
    this.typeWriter();

    this.initializeSwiper();


    // -----------Count UP Projects Observe--------
    //  mind. 50% of the element in case of Intersection successfully
    const options = {
          threshold: 0.5,
        };

    const observer = new IntersectionObserver(this.handleIntersection.bind(this), options);
    observer.observe(this.$refs.yearsCount);
    observer.observe(this.$refs.projectsCount);
    observer.observe(this.$refs.coffeesCount);

    // Start count-up animation initially
    this.animateCountUp('yearsCount', this.targetCounts.yearsCount, this.delays.yearsCount);
    this.animateCountUp('projectsCount', this.targetCounts.projectsCount, this.delays.projectsCount);
    this.animateCountUp('coffeesCount', this.targetCounts.coffeesCount, this.delays.coffeesCount);
    // ----------- ---------


    // Preload Project Cards Images
    this.preloadImages();
    
    // Observer für AboutMeName
    const observerAboutMe = new IntersectionObserver(this.handleIntersectionAboutMe.bind(this), options);
    observerAboutMe.observe(this.$refs.aboutMeName);

    // Observer für Radial Bars
    const observerIntersection = new IntersectionObserver(this.handleIntersectionRadial.bind(this), options);
    observerIntersection.observe(this.$refs.radialBars);

    // Observer für Technical Bars
    const observerTechnical = new IntersectionObserver(this.handleIntersectionTechnical.bind(this), options);
    observerTechnical.observe(this.$refs.technicalBars);

    // Observer für contactAnimation
    const observerContact = new IntersectionObserver(this.handleIntersectionContact.bind(this), options);
    observerContact.observe(this.$refs.contactAnimation);
  },

  methods: {
    
    //-------------------------- Slider projects ----------------------------
    initializeSwiper() {
      this.swiper = new Swiper(".slide-content", {
        
        slidesPerView: 3,
        spaceBetween: 25,
        //slidesPerGroup: 3,
        loop: true,
        centerSlide:'true',
        fade:'true',
        //grabCursor:'true',
        //loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          dynamicBullets:true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints:{
          0:{
              slidesPerView:1,
          },
          520:{
              slidesPerView:2,
          },
          950:{
              slidesPerView:3,
          },
        },

      });
    },

    openLink(url) {
      window.open(url, '_blank');
    },

    getIconLink(icon) {
    if (icon === 'mdi-facebook') {
      return 'https://www.facebook.com/tobias.forsthofer.1';
    } else if (icon === 'mdi-twitter') {
      return 'https://twitter.com/tobi_f27';
    } else if (icon === 'mdi-linkedin') {
      return '#';
    } else if (icon === 'mdi-instagram') {
      return 'https://instagram.com/forsthofer.tobias9?igshid=NTc4MTIwNjQ2YQ==';
    }
    return '#';
    },

    //--------------------------TypeWriter----------------------------------------
    typeWriter() {
      if (this.isTyping) {
        let i = 0;
        const speed = 50; // Adjust the typing speed (milliseconds per character)

        const type = () => {
          if (i < this.texts[this.textIndex].length) {
            this.animatedText += this.texts[this.textIndex].charAt(i);
            i++;
            setTimeout(type, speed);
          } else {
            this.containerHeight = "auto"; // Set the container height to auto when typing is finished
            setTimeout(this.eraseText, 1000); // Wait for 1 second, then start erasing
          }
        };

        type();
      } else {
        // Erasing
        if (this.animatedText.length > 0) {
          this.animatedText = this.animatedText.slice(0, -1);
          setTimeout(this.typeWriter, 50);
        } else {
          this.containerHeight = "1rem"; // Set the container height back to the initial value
          this.textIndex = (this.textIndex + 1) % this.texts.length;
          this.isTyping = true;
          setTimeout(this.typeWriter, 1000); // Wait for 1 second before typing the next text
        }
      }
    },
    eraseText() {
      this.isTyping = false;
      this.typeWriter();
    },

    /* Picture bei Projects anzeigen Cards */
    showOverlay() {
      this.isHovered = true;
    },
    hideOverlay() {
      this.isHovered = false;
    },

  //-------------------------- count Up Projects ----------------------------
  animateCountUp(refName, targetCount, delay) {
    let currentCount = 0;

    const updateCount = () => {
      if (currentCount < targetCount) {
        this.animatedCounts[refName] = currentCount + 1;
        currentCount++;
        setTimeout(updateCount, delay);
      }
    };

    updateCount();
  },


  onVisibilityChange(refName, isVisible) {
    if (isVisible) {
      this.animateCountUp(refName, this.targetCounts[refName], this.delays[refName]);
    }
  },



  handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          switch (entry.target) {
            case this.$refs.yearsCount:
              this.onVisibilityChange('yearsCount', true);
              break;
            case this.$refs.projectsCount:
              this.onVisibilityChange('projectsCount', true);
              break;
            case this.$refs.coffeesCount:
              this.onVisibilityChange('coffeesCount', true);
              break;
          }
        }
      });
    },
  //--------------------------Radial bars Observe ----------------------------
  /*animateRadialBars() {
      //Check that the Radial Bar Div should be visible
      if (this.$refs.radialBars && this.isElementInViewport(this.$refs.radialBars)) {
        this.showRadialBar = true;
      } else {
        this.showRadialBar = false;
      }
    },

    isElementInViewport(el) {
      //Check if the element is visible in the viewport
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    */
    handleVisibilityChange(isVisible) {
      if (isVisible) {
        this.showRadialBar = true;
      } else {
        this.showRadialBar = false;
      }
    },

    handleIntersectionRadial(entries) {
      entries.forEach(entry => {
        this.handleVisibilityChange(entry.isIntersecting);
      });
    },


      //--------------------------Technical bars Observe ----------------------------

    handleVisibilityChangeTechnical(isVisible) {
      if (isVisible) {
        this.showTechnicalBars = true;
      } else {
        this.showTechnicalBars = false;
      }
    },

    handleIntersectionTechnical(entries) {
      entries.forEach(entry => {
        this.handleVisibilityChangeTechnical(entry.isIntersecting);
      });
    },

    //--------------------------About Me Observe ----------------------------

    handleVisibilityChangeAboutMe(isVisible) {
      if (isVisible) {
        this.showNameBar = true;
      } else {
        this.showNameBar = false;
      }
    },

    handleIntersectionAboutMe(entries) {
      entries.forEach(entry => {
        this.handleVisibilityChangeAboutMe(entry.isIntersecting);
      });
    },

    //-------------------------- Contact Animation ----------------------------

    handleVisibilityChangeContact(isVisible) {
      if (isVisible) {
        this.showContactAnimation = true;
      } else {
        this.showContactAnimation = false;
      }
    },

    handleIntersectionContact(entries) {
      entries.forEach(entry => {
        this.handleVisibilityChangeContact(entry.isIntersecting);
      });
    },

    //----------------- Scroll to Top ---------------------//
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    //----------------- Scroll Function ---------------------//
    scroll(refName) {
      const element = document.getElementById(refName);
        if (element) {
            console.log('Scrollen zum Element mit ID:', refName);
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.log('Element mit ID', refName, 'nicht gefunden');
        }
    },


    //----------------- Preload Projects Cards Images ---------------------//
    preloadImages() {
      const images = [
        require("../assets/img/tnt_diplo.png"),
        require("../assets/img/cms_home.png"),
        require("../assets/img/delay_sports.png"),
        require("../assets/img/home_typo.png"),
        // ... füge hier weitere Bildpfade hinzu, wenn nötig
      ];

      images.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    },




  },


  data() {
    return {
      texts: ["Full-Stack Developer.", "Web Developer.", "UI/UX-Designer.", "Creativity.", "Passion."],
      animatedText: "",
      textIndex: 0,
      isTyping: true,
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      isHovered: false,
      targetCounts: {
        yearsCount: 5,
        projectsCount: 10,
        coffeesCount: 100,
      },
      delays: {
        yearsCount: 900,
        projectsCount: 500,
        coffeesCount: 40,
      },
      animatedCounts: {
        yearsCount: 0,
        projectsCount: 0,
        coffeesCount: 0,
      },

      //--------------------------Radial bars Observe ----------------------------
      showNameBar:false,
      showRadialBar: false,
      showTechnicalBars: false,
      showContactAnimation:false,
    };
  },
}
</script>

<style scoped lang="scss">

  @import url('../assets/css/normalize.css');
  @import url('../assets/css/main.css');
  @import url('../assets/css/styleanpassungen.css');
  @import url('../assets/css/home.css');
  @import url('../assets/css/animation/typewriter.css');
  @import url('../assets/css/animation/waveanimation.css');
  @import url('../assets/css/animation/arrow.css');
  @import url('../assets/css/animation/arrow_up.css');

  

</style>
