<template>
  <!--<v-footer style="width:100%;background-color:yellow;bottom:0px;text-align:center;padding:0px;"
    
    padless
  >-->
  <v-footer style="width:100%;background-color:yellow; text-align:center;padding:0px; position:absolute; bottom:0;">

    <v-card
      flat
      tile
      class="lighten-1 white--text text-center"
      style="width:100%;border:0;" 
    >

    <!--#1F2933 #101214-->
    <v-card-text style="width:100%;height:5px; background-color:#0C131A;" >
      </v-card-text>

    <v-card-text class="white--text" style="width:100%;background-color:#000000;font-size:1.3rem;" >
              <span style="font-size:1rem;color:white;"><span style="font-weight:bold;font-weight: 450;">&copy; 2023 Tobias Forsthofer.</span> All rights reserved.</span>
      </v-card-text>

      <v-card-text style="background-color:#000000;width:100%;padding-top:0px;padding-bottom:30px;">
      
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-1 white--text no-box-shadow"
          icon
          @click="openLink(getIconLink(icon))"
        >
          <v-icon size="24px" class="white--text" >
            {{ icon }}
          </v-icon>
        </v-btn>
      
        <span class="padding-entfernen">

        </span>
        <!--Strich dazwischen-->
        <span class="strich-entfernen">

        </span>

        <router-link to="/impressum" style="margin-top: 13px; font-size: 1rem; color: white;text-decoration:none">
          <span class="impressum-button" >Impressum</span>
        </router-link>



      </v-card-text>

    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  methods: {
  showImpressumFooter() {
    this.$emit('Impressum')
  },

  openLink(url) {
    window.open(url, '_blank');
  },

  getIconLink(icon) {
  if (icon === 'mdi-facebook') {
    return 'https://www.facebook.com/tobias.forsthofer.1';
  } else if (icon === 'mdi-twitter') {
    return 'https://twitter.com/tobi_f27';
  } else if (icon === 'mdi-linkedin') {
    return '#';
  } else if (icon === 'mdi-instagram') {
    return 'https://instagram.com/forsthofer.tobias9?igshid=NTc4MTIwNjQ2YQ==';
  }
  return '#';
},
},

  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
  }),
}
</script>
<!--
<style src="../assets/css/normalize.css"></style>
<style src="../assets/css/normalize2.css"></style>
<style src="../assets/css/main.css"></style>
<style src="../assets/css/skeleton.css"></style>
<style src="../assets/css/style.css"></style>
-->
<style>

.impressum-button {
    text-decoration:none;

}

.impressum-button:hover {
  text-decoration:underline;
  

}

.no-box-shadow {
  box-shadow: none;
  background-color:#000000;
}

.v-btn.no-box-shadow:hover {
  box-shadow: 0 4px 8px rgba(9,13,18,1), 0 8px 16px rgba(9,13,18,1);
  /*background-color:rgba(9,13,18,1);*/

}

.v-card {
  border-radius: 0;
}

.v-card {
  border: none;
}
.v-icon.white--text {
  color: white;
}

.strich-entfernen {
    border-left:1px solid white;
    padding-left:28px;
}


@media (max-width: 400px) {
  .strich-entfernen {
    border-left:none;
    padding-left:0px;
  }
}

.padding-entfernen {
  padding-right:16px;
}


@media (max-width: 400px) {
  .padding-entfernen {
    padding-right:0px;

  }

  .impressum-button {
    display: block;
    text-align: center;
    margin-top: 5px;
  }
}
</style>