<template>
    <Navbar/>
      <section id="project3Site">
        <div class="project-content" style="margin-bottom:200px;">
          <br>
          <br>
          <br>
          <div style="height:20px;">
            <v-img @click="goBack" class="back-icon" src="../assets/back_icon.svg" alt="Back Icon"></v-img>
          </div>

          <div class="project-details" style="background-color:white">

            <p class="small-text">SCHOOL PROJECT</p>
            <p class="large-text">CICD-Design Package </p>
            <hr style="border-color: #29425c; width: 100px; margin-right: auto; margin-left: auto; border-width: 3px; border-radius: 5px;margin-top:5px; margin-bottom:10px;">
            <p class="medium-text"> As part of a competition, a CI/CD design package 
                        was developed for the <span style="font-style:italic;">Delay Sports</span> football club. </p>
          </div>
          
          
          <div class="custom-section">
            <div class="custom-title" >
              <p>Overview</p>
              <hr style="border-color: black; width: 100px; border-width: 1px; border-radius: 5px; ">
            </div>
            <div class="custom-text">
 
            Corporate Design (CD) refers to the external visual identity of a company. It encompasses graphic elements such as the logo,
             color scheme, typography, website design, and other visual elements. 
             The primary objective is to establish a consistent and recognizable appearance
              that reflects the values and personality of the company.
            </div>
          </div>

          <div class="custom-section2">
            <div class="custom-text2">
              As part of a competition, a Corporate Design package was created for Elias Nerlich, also known as the YouTuber "Eligella," 
              for his newly founded football club "Delay Sports." These design elements were crafted using appropriate Adobe products. 
              Furthermore, a website was developed to showcase the created design elements. 
              The implementation also placed significant emphasis on UI/UX design.
            </div>
            <div class="custom-title2" >
              <p>Realisation</p>
              <hr style="border-color: black; width: 100px; border-width: 1px; border-radius: 5px; ">
            </div>
          </div>
          <br>
          <br>
          <div class="galleryUeberschrift">
              <span style="font-family:Montserrat-Bold">Project</span> <span style="font-family:Montserrat-Light;">Photos</span>
              <hr class="photoHr" style="border-color: #29425c; width: 180px;border-width: 3px; border-radius: 4px; margin-top:0px;margin-bottom:0px;">
          </div>

          <div class="grid-gallery" style="padding-bottom:1px;">

            <div class="grid-item">
              <a :href="require('../assets/img/project3/delay_sports6.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project3/delay_sports6.png')" />

              </a>
            </div>

            <div class="grid-item">
              <a :href="require('../assets/img/project3/delay_sports1.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project3/delay_sports1.png')" />

              </a>
            </div>

            <div class="grid-item">
              <a :href="require('../assets/img/project3/delay_sports2.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project3/delay_sports2.png')" />

              </a>
            </div>

            <div class="grid-item">
              <a :href="require('../assets/img/project3/delay_sports3.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project3/delay_sports3.png')" />

              </a>
            </div>

            <div class="grid-item">
              <a :href="require('../assets/img/project3/delay_sports4.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project3/delay_sports4.png')" />

              </a>
            </div>

            <div class="grid-item">
              <a :href="require('../assets/img/project3/delay_sports5.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project3/delay_sports5.png')" />

              </a>
            </div>










            
            
          </div>


        
             <!-- Zurück-Button -->
          <v-btn @click="goBack" class="back-button buttonVue" style="flex-basis: 100%;margin-top:40px;">
            <v-icon>mdi-chevron-left</v-icon>
              Back
          </v-btn>

          <br>
          <br>




        </div>


      </section>
    <Footer/>
</template>
  
  <script>
  import Navbar from '../components/NavbarComponent.vue'
  import Footer from '../components/FooterComponent.vue'
  import $ from 'jquery';
  import 'lightbox2/dist/css/lightbox.min.css';
  import lightbox from 'lightbox2/dist/js/lightbox-plus-jquery.min';

  export default {
      name: 'DetailsPageProject1',
  
      components: {
       Navbar,
       Footer,
      },
      
      data: () => ({
  
      }),

      mounted() {
      // Verwende jQuery für Lightbox
      lightbox.option({
        'resizeDuration': 200,
        'disableScrolling': true,
        'alwaysShowNavOnTouchDevices': true,
        'wrapAround': true,

        'jQuery': $,

      });
    },
      methods: {
        goBack() {
          // Zurück zur vorherigen Seite
          window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        }
      }
    }
  </script>

  <style>

  </style>
    