
import { createRouter, createWebHistory } from 'vue-router';

import HomePage from './views/HomePage.vue';
import ImpressumPage from './views/ImpressumPage.vue';
import DetailsPageProject1 from './views/DetailsPageProject1.vue';
import DetailsPageProject2 from './views/DetailsPageProject2.vue';
import DetailsPageProject3 from './views/DetailsPageProject3.vue';
import DetailsPageProject4 from './views/DetailsPageProject4.vue';


const routes = [
    { path: '/', component: HomePage },
    { path: '/impressum', component: ImpressumPage },
    { path: '/detailsProject1', component: DetailsPageProject1 },
    { path: '/detailsProject2', component: DetailsPageProject2 },
    { path: '/detailsProject3', component: DetailsPageProject3 },
    { path: '/detailsProject4', component: DetailsPageProject4 },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {  
    // Wenn es eine gespeicherte Position gibt, verwende sie
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) { // Vergleich ob # in der URL ist
      //in navbar wurde bei push # tag angehängt so wird nichts gemacht und es wird nicht nach oben gescrollt
      
    } else {
      // Ansonsten lasse das Scrollverhalten unverändert
      return { top: 0 };
    }
  },
});

export default router;
