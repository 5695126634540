<template>
  <v-app id="home" :style="{ background: $vuetify.theme.themes.dark.background }">
    <v-main>
      <router-view /> <!-- Hier werden die Inhalte der verschiedenen Seiten angezeigt -->
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
}
</script>

<style>
@import './assets/css/fonts.css';
</style>
