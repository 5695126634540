<template>
    <Navbar/>
      <section id="project1Site">
        <div class="project-content" style="margin-bottom:200px;">
          <br>
          <br>
          <br>
          <div style="height:20px;">
            <v-img @click="goBack" class="back-icon" src="../assets/back_icon.svg" alt="Back Icon"></v-img>
          </div>

          <div class="project-details" style="background-color:white">

            <p class="small-text">DIPLOMA THESIS</p>
            <p class="large-text">TNT - „The New Time“ </p>
            <hr style="border-color: #29425c; width: 100px; margin-right: auto; margin-left: auto; border-width: 3px; border-radius: 5px;margin-top:5px; margin-bottom:10px;">
            <p class="medium-text">Migration of time recording to a microfrontend architecture with open RESTFUL backend standard</p>
          </div>
          
          
          <div class="custom-section">
            <div class="custom-title" >
              <p>Overview</p>
              <hr style="border-color: black; width: 100px; border-width: 1px; border-radius: 5px; ">
            </div>
            <div class="custom-text">
              The company "Logicx" needs a new time recording application because the
              existing application is based on the Microsoft Silverlight plug-in and therefore
              only runs in Internet Explorer.
              So the goal is to redesign and reimplement this application using the Logicx-
              Microfrontend framework based on vue.js, as well as the Logicx backend
              framework to create a restful backend layer for the persistence layer using .net
              Core (C#). The data is maintained in an MS SQL-server database.
            </div>
          </div>

          <div class="custom-section2">
            <div class="custom-text2">
              The existing Time application serves as the starting point and template for the
              required functions. The use cases, frontend service capsules and backend
              resources are created from this. The Logicx microfrontend framework based on
              vue.js is used to programm the web interface. To create a restful backend layer for
              the persistence layer, the Logicx backend framework with the .Net Core (C#)
              programming language is used.
              The data is stored in an MS SQL-server database. Visual Studio is used as the
              development environment for creating an API. Visual Studio Code is used as the
              development environment for implementing the frontend.
            </div>
            <div class="custom-title2" >
              <p>Realisation</p>
              <hr style="border-color: black; width: 100px; border-width: 1px; border-radius: 5px; ">
            </div>
          </div>
          <br>
          <br>
          <div class="galleryUeberschrift">
              <span style="font-family:Montserrat-Bold">Project</span> <span style="font-family:Montserrat-Light;">Photos</span>
              <hr class="photoHr" style="border-color: #29425c; width: 180px;border-width: 3px; border-radius: 4px; margin-top:0px;margin-bottom:0px;">
          </div>

          <div class="grid-gallery" style="padding-bottom:1px;">

            <div class="grid-item">
              <a :href="require('../assets/img/project1/architecture1.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project1/architecture1.png')" />

              </a>
            </div>

            <div class="grid-item">
              <a :href="require('../assets/img/project1/frontendServiceKapselReisen.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project1/frontendServiceKapselReisen.png')" />

              </a>
            </div>

            <div class="grid-item">
              <a :href="require('../assets/img/project1/frontendServiceKapselSpesen.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project1/frontendServiceKapselSpesen.png')" />

              </a>
            </div>
            <div class="grid-item">
              <a :href="require('../assets/img/project1/frontendSinglePageUiContainer.png')" data-lightbox="gridImage"> 
                <img :src="require('../assets/img/project1/frontendSinglePageUiContainer.png')" />

              </a>
            </div>
            <div class="grid-item">
              <a :href="require('../assets/img/project1/projektstrukturplan.jpg')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project1/projektstrukturplan.jpg')" />

              </a>
            </div>

            <div class="grid-item" >
              <a :href="require('../assets/img/project1/plattformStandard.png')" data-lightbox="gridImage">
                <img :src="require('../assets/img/project1/plattformStandard.png')" />

              </a>
            </div>



            
            
          </div>


        
             <!-- Zurück-Button -->
          <v-btn @click="goBack" class="back-button buttonVue" style="flex-basis: 100%;margin-top:0px;">
            <v-icon>mdi-chevron-left</v-icon>
              Back
          </v-btn>

          <br>
          <br>




        </div>


      </section>
    <Footer/>
</template>
  
  <script>
  import Navbar from '../components/NavbarComponent.vue'
  import Footer from '../components/FooterComponent.vue'
  import $ from 'jquery';
  import 'lightbox2/dist/css/lightbox.min.css';
  import lightbox from 'lightbox2/dist/js/lightbox-plus-jquery.min';

  export default {
      name: 'DetailsPageProject1',
  
      components: {
       Navbar,
       Footer,
      },
      
      data: () => ({
  
      }),

      mounted() {
      // Verwende jQuery für Lightbox
      lightbox.option({
        'resizeDuration': 200,
        'disableScrolling': true,
        'alwaysShowNavOnTouchDevices': true,
        'wrapAround': true,

        'jQuery': $,

      });
    },
      methods: {
        goBack() {
          // Zurück zur vorherigen Seite
          window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
        }
      }
    }
  </script>

  <style>


  </style>
    