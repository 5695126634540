import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './router'; // Importieren Sie den Router aus der router.js-Datei

loadFonts();

createApp(App)
  .use(vuetify)
  .use(router) // Fügen Sie den Router hinzu
  .mount('#app');