<template>
  <div>
    
      <!-- Div-Element mit Hintergrundfarbe //Hintergrund blau machen Balken wegbringen!!!!!!!!!! -->
      <div class="overlay" :style="{ width: overlayWidth + 'px', transition: overlayTransition }"></div>



      <v-app-bar
        app
        :color="navBarColor"
        dark
        flat
        style="margin-bottom:20px;"
        dense
      >
      <!--
      <v-img
            src="../assets/logos/logo.png"
            alt="Logo"
            style="height:80px;padding:5px;background-color:yellow;"
            @click="reloadHomePage"
        ></v-img>-->

      <div style="width:30%;min-width:300px;">
        <v-img
            src="../assets/logos/logo.png"
            alt="Logo"
            style="padding:5px;padding-top:10px;"
            @click="reloadHomePage"
            class="bildgroeße nav-logo"
        ></v-img>
      </div>


        <v-spacer></v-spacer>

        <v-btn v-if="showRegularNav" text @click="scroll('home')" class="no-padding-top" style="color:white;">Home</v-btn>
        <v-btn v-if="showRegularNav" text @click="scroll('about')" class="no-padding-top" style="color:white;">About</v-btn>
        <v-btn v-if="showRegularNav" text @click="scroll('project')" class="no-padding-top" style="color:white;">Projects</v-btn>
        <v-btn v-if="showRegularNav" text @click="scroll('contact')" class="no-padding-top" style="color:white;"> Contact</v-btn>

        <v-btn v-else icon @click="handleDrawer">
          <v-icon color="white">{{ drawer ? 'mdi-close' : 'mdi-menu' }}</v-icon>
        </v-btn>
      </v-app-bar>

      <!--Burger Menu-->
      <v-navigation-drawer
        v-model="drawer"
        app
        color="#000000"
        dark
        width="100%"
        v-show="!showRegularNav"
        class="mt-0"
      >

        <v-list>
          <v-list-item v-for="item in navigationItems" :key="item.text" @click="scroll(item.refName)">
            <v-list-item-title class="burger-menu-text">{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <hr style="width: 50%; margin: 0 auto; transform: translateY(50%);">

        <p style="color:white;text-align:center; padding-top:5%;font-weight:bold;">Contact Me:</p>

        <div class="iconsWithoutUnderline" style="display: flex; justify-content: center; align-items: center; padding: 16px;">
          <a href="https://instagram.com/forsthofer.tobias9?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noopener noreferrer">
            <v-icon style="color: white; margin: 8px;">mdi-instagram</v-icon>
          </a>
          <a href="https://www.facebook.com/tobias.forsthofer.1" target="_blank" rel="noopener noreferrer">
            <v-icon style="color: white; margin: 8px;">mdi-facebook</v-icon>
          </a>
          <a href="mailto:tobias.forsthofer@icloud.com">
            <v-icon style="color: white; margin: 8px;">mdi-email</v-icon>
          </a>
        </div>

        <div style="padding-top:10%;text-align:center;width:50%;margin-left:auto;margin-right:auto;">
            <v-img
              src="../assets/logos/schriftzug.png"
              alt="Logo"
              style=""
            ></v-img>
        </div>

      </v-navigation-drawer>


  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
      showRegularNav: true,
      navigationItems: [
        { text: "Home", refName: "home" },
        { text: "About", refName: "about" },
        { text: "Projects", refName: "project" },
        { text: "Contact", refName: "contact" },
      ],
      navBarColor: "#000000", // Standardfarbe der Navigationsleiste
      overlayWidth: 0, // Breite des Overlays, anfangs auf 0 setzen
    };
  },

  watch: {
    drawer(value) {
      if (value) {
        document.documentElement.style.overflow = 'hidden';
        document.body.style.overflow = 'hidden';

        //Hintergrund blau machen Balken wegbringen!!!!!!!!!!
        // Wenn Drawer geöffnet wird, setzen Sie die Transition auf 0.3s
        this.overlayTransition = "width 0.3s ease";

      } else {
        document.documentElement.style.overflow = '';
        document.body.style.overflow = '';

        //Hintergrund blau machen Balken wegbringen!!!!!!!!!!
        // Wenn Drawer geschlossen wird, setzen Sie die Transition auf "none"
        this.overlayTransition = "none";
        // Setzen Sie die Breite des Overlays sofort auf 0
        this.overlayWidth = 0;
      }
    },
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    
    //Navigationsleiste auf #00000 stellen beim Öffnen
    handleDrawer() {
      this.drawer = !this.drawer;

      // Ändern der Navigationsleistenfarbe basierend auf dem Drawer-Zustand
      if (this.drawer) {
        this.navBarColor = "#000000"; // Farbe auf #000000 ändern, wenn geöffnet
      } else {
        this.handleScroll(); // Aufruf der handleScroll-Methode, um die Farbe basierend auf dem Scrollverhalten festzulegen
      }


      //-------------------------------------------------------------------------------------------------------------
      //Hintergrund blau machen Balken wegbringen!!!!!!!!!!
      if (this.drawer) {
        // Öffnen Sie den Drawer und setzen Sie die Breite des Overlays auf die Bildschirmbreite
        this.overlayWidth = window.innerWidth;
      } else {
        // Schließen Sie den Drawer und setzen Sie die Breite des Overlays auf 0
        this.overlayWidth = 0;
      }
      //--------------------------------------------------------------------------------

    },



    scroll(refName) {
    // Überprüfen Sie, ob die aktuelle Seite nicht die Homepage ist, und navigieren Sie dann zur Homepage
    if (this.$route.path !== '/') {
        // Scrollen im Rückruf-Handler von $router.push
        this.$router.push('/#').then(() => {   // Hastag wurde hinzugefügt das in router js in der else if zwei hineinkommt
            const element = document.getElementById(refName);
            if (element) {
                console.log('Scrollen zum Element mit ID:', refName);
                element.scrollIntoView({ behavior: 'smooth' });
            } else {
                console.log('Element mit ID', refName, 'nicht gefunden');
            }

            
        });
    } else {
        // Seite ist bereits die Homepage, direkt scrollen
        const element = document.getElementById(refName);
        if (element) {
            console.log('Scrollen zum Element mit ID:', refName);
            element.scrollIntoView({ behavior: 'smooth' });
        } else {
            console.log('Element mit ID', refName, 'nicht gefunden');
        }

        
    }

    this.drawer = false; // das war der Fehler das es eingefroren ist
},

    handleScroll() {
      // Überprüfen, ob nach unten gescrollt wird und das Burger-Menü nicht geöffnet ist
      if (window.pageYOffset > 0 && !this.drawer) {
        this.navBarColor = "#0C131A"; // Neue Farbe der Navigationsleiste
      } else {
        this.navBarColor = "#000000"; // Zurück zur Standardfarbe der Navigationsleiste
      }
    },

    
    handleWindowResize() {
      if (window.innerWidth < 800) {
        this.showRegularNav = false;
      } else {
        this.showRegularNav = true;
        this.drawer = false;
      }
    },

    reloadHomePage() {
      window.location.href = "/"; // Die URL der Startseite anpassen, falls erforderlich
    },
  },
};
</script>


<style>


/*------------------------------------------------------------------------------------------ */
/*Hintergrund blau machen Balken wegbringen!!!!!!!!!!*/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #465869;
  z-index: 1;
  pointer-events: none;
  transition: width 0.3s ease; /* Hinzufügen einer Transition für die Breite */
}

/* Stil für das Overlay, wenn das Burger-Menü geöffnet ist */
.overlay.active {
  width: 100%; /* Breite auf 100% ändern, wenn das Burger-Menü geöffnet ist */
}
/*------------------------------------------------------------------------------------------*/

/* Entfernen Sie die Unterstreichungen von Links */
.iconsWithoutUnderline a{
  text-decoration: none; /* Entfernt Unterstreichungen */
  color: inherit; /* Verwendet die Farbe des Elternelements (falls vorhanden) */
}


/* Für besuchte Links können Sie die Farbe ändern, wenn gewünscht */
.iconsWithoutUnderline a:visited{
  text-decoration: none; /* Entfernt Unterstreichungen */
  color: inherit; /* Verwendet die Farbe des Elternelements (falls vorhanden) */
}


/* img Bild größe */
.bildgroeße{
  height: 60px;
}


@media (max-width: 599px) {
  .bildgroeße{
    height: 50px;
  }
}


.no-padding-top {
  padding-top: 0 !important;
}

.v-app-bar {
  height: 50px !important;
}

.v-navigation-drawer {
  height: 100vh !important;
}

.v-navigation-drawer .v-list-item {
  color: white !important;
}

.v-application--wrap {
  overflow-y: hidden !important;
}

@media (max-width: 799px) {
    .v-app-bar {
      justify-content: flex-end !important;
      margin-bottom: 0 !important;
    }

    .v-navigation-drawer {
      width: 100% !important;
      height: 100vh !important;
      margin-top: 0 !important;
    }
  }

  @media (max-width: 599px) {
    .v-app-bar {
      justify-content: center !important;
      margin-bottom: 0 !important;
    }

    .v-navigation-drawer {
      width: 100% !important;
      height: 100vh !important;
      margin-top: 0 !important;
    }
  }

.burger-menu-text {
  color: white;
  text-align: center;
  font-size:1.3rem;
}

.v-app-bar {
  margin-top: 0;
}

.v-app-bar .v-btn {
  margin-bottom: 0px !important;
}


.v-navigation-drawer {
  margin-top: 0;
  margin-bottom: 0;
}

.v-toolbar__content {
  height: 50px !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
}



.nav-logo .v-img__img{
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}


.v-main {
  --v-layout-top: 0px !important;
}
</style>
